import React, { useReducer } from 'react';
import DataAndMethodsContext from './dataAndMethodsContext';
import DataAndMethodsReducer from './dataAndMethodsReducer';
import setMyStatesLogic from '../../utilities/setMyStatesLogic';
// import getLocation from '../../model/getLocation';

import {
  SET_MY_STATES,
  SET_TODAYS_DATE,
  SET_SELECTED_DATE,
  SET_LOADING,
  SET_LOADING_DIALOG,
  SET_SIGN_IN_REG_DIALOG_TYPE,
  SET_SIGN_IN_REG_DIALOG_TITLE,
  SET_AUTH_TOKEN,
  SET_ID_TOKEN,
  SET_CUSTOM_ID,
  SET_LOGIN_TYPE,
  SET_GYM_MEMBER,
  SET_GYM_MEMBERS,
  SET_GYM_MEMBER_DIALOG_DATA,
  SET_GYM_MEMBER_DIALOG_OPEN,
  SET_EXERCISE_ITEMS,
  SET_EXERCISE_ITEM_DIALOG_DATA,
  SET_EXERCISE_ITEM_DIALOG_OPEN,
  SET_WORKOUT_ITEMS,
  SET_WORKOUT_DIALOG_OPEN,
  SET_WORKOUT_DIALOG_DATA,
  SET_GYM_DAY_ITEMS,
  SET_GYM_DAY_DIALOG_OPEN,
  SET_GYM_DAY_DIALOG_DATA,
  SET_PHOTOS,
  SET_PHOTO_DIALOG_DATA,
  SET_PHOTO_DIALOG_OPEN,
  SET_IMAGE_EDITOR_DATA,
  SET_TODAYS_WORKOUTS,
  SET_EXERCISE_DAY,
  SET_EXERCISES_PREVIOUS,
} from '../types';
import validDate from '../../utilities/validDate';
import dateString from '../../utilities/dateString';

const DataAndMethodsState: any = (props: { children: any }) => {
  const [myStatesLocalStorage] = React.useState(() => {
    let myStates: any = window.localStorage.getItem('iWantToDine.myStates');
    myStates =
      myStates !== null
        ? JSON.parse(myStates)
        : {
            // menu categories
            steam: false,
            diesel: false,
            electric: false,
            passenger: false,
            operatingCars: false,

            // dates
            date_0: false,
            date_1: false,
            date_2: false,
            date_3: false,
            date_4: false,
            date_5: false,
            date_6: false,

            // backend pages
            trainSettings: false,
            trainSetSettings: false,
            gymDaySettings: false,
            hobbyistSettings: false,
            showDescription: false,

            // sorting types
            sortTitle: false,
            sortPrice: false,
            sortDate: false,
            sortTime: false,
            sortName: false,

            lastState: 'trains',

            // help dialog
            helpDialogStage: 0,
            helpDialogActive: false,
            helpDialogOpen: false,
          };
    // dates
    myStates.date_0 = true;
    myStates.date_1 = false;
    myStates.date_2 = false;
    myStates.date_3 = false;
    myStates.date_4 = false;
    myStates.date_5 = false;
    myStates.date_6 = false;
    return myStates;
  });

  const initialState = {
    myStates: myStatesLocalStorage,

    todaysDate: Date(),
    selectedDate: Date(),

    loading: false,
    loadingDialog: false,

    signInRegDialogType: 'false',
    signInRegDialogTitle: '',
    authToken: {},
    idToken: {},
    customId: '',
    logInType: 'default',

    hobbyist: {},
    hobbyists: [],
    hobbyistDialogOpen: false,
    hobbyistDialogData: {
      id: '',
      firstName: '',
      lastName: '',
      bio: '',
      email: '',
      trainIdsJSON: [],
      friendIdsJSON: [],
      gymDayIdsJSON: [],
      trainSetIdsJSON: [],
      dataJSON: [],
      trainDaysJSON: {},
      dialogType: 'Edit',
      message: '',
    },

    trains: [],
    trainItemDialogOpen: false,
    trainItemDialogData: {
      id: '',
      title: '',
      modelNumber: '',
      description: '',
      roadName: '',
      yearBought: '',
      trainLocation: '',
      boxLocation: '',
      boughtPrice: 0,
      marketPrice: 0,
      categoryJSON: [],
      videoUrl: '',
      imageUrl: '',
      dialogType: 'Edit',
    },

    trainSets: [],
    trainSetDialogOpen: false,
    trainSetDialogData: {
      id: '',
      title: '',
      trainIdsJSON: [],
      dialogType: 'Edit',
    },

    gymDays: [],
    gymDayDialogOpen: false,
    gymDayDialogData: {
      id: '',
      title: '',
      dateFrom: '',
      dateTo: '',
      dayJSON: [],
      days: [],
      trainSetIdsJSON: [],
      dialogType: 'Edit',
    },

    photos: [],
    photoDialogOpen: false,
    photoDialogData: {
      src: '',
      caption: '',
      dialogType: 'Add',
    },

    imageEditorData: {
      imageUrl: '',
      editMode: 'none',
      deleteFileName: '',
      width: 1,
      height: 1,
      aspectRatio: 1,
      blob: '',
      showDelete: true,
    },

    todaysTrainSets: [],

    trainDay: {},

    trainsPrevious: {},
  };

  const [state, dispatch] = useReducer(DataAndMethodsReducer, initialState);

  //set my states -----------------------------------------------------
  const setMyState = async (key: any) => {
    let myNewStateChoices = JSON.parse(JSON.stringify(state.myStates));
    myNewStateChoices = setMyStatesLogic(myNewStateChoices, key);
    window.localStorage.setItem(
      'iWantToDine.myStates',
      JSON.stringify(myNewStateChoices)
    );
    setMyStates(myNewStateChoices);
  };
  const setMyStates = async (myStates: any) => {
    dispatch({ type: SET_MY_STATES, payload: myStates });
  };

  // set date -------------------------------------------------------------------------------
  const setTodaysDate = (todaysDate: any) =>
    dispatch({ type: SET_TODAYS_DATE, payload: todaysDate });
  const setSelectedDate = (selectedDate: any) =>
    dispatch({ type: SET_SELECTED_DATE, payload: selectedDate });

  // set loading spinner ---------------------------------------------------------------------
  const setLoading = (myBool: boolean) =>
    dispatch({ type: SET_LOADING, payload: myBool });
  const setLoadingDialog = (myBool: any) =>
    dispatch({ type: SET_LOADING_DIALOG, payload: myBool });

  // login dialog and authorization items ------------------------------
  const setSignInRegDialogType = async (signInRegDialogType: any) => {
    dispatch({
      type: SET_SIGN_IN_REG_DIALOG_TYPE,
      payload: signInRegDialogType,
    });
  };
  const setSignInRegDialogTitle = async (signInRegDialogTitle: any) => {
    dispatch({
      type: SET_SIGN_IN_REG_DIALOG_TITLE,
      payload: signInRegDialogTitle,
    });
  };
  const setAuthToken = async (authToken: any) => {
    dispatch({ type: SET_AUTH_TOKEN, payload: authToken });
  };
  const setCustomId = async (customId: any) => {
    dispatch({ type: SET_CUSTOM_ID, payload: customId });
  };
  const setIdToken = async (idToken: any) => {
    dispatch({ type: SET_ID_TOKEN, payload: idToken });
  };
  const setLogInType = async (logInType: any) => {
    dispatch({ type: SET_LOGIN_TYPE, payload: logInType });
  };

  // hobbyists and dialog --------------------------------------------
  const setHobbyistDialogDataItem = async (key: string, value: any) => {
    let hobbyistDialogData = JSON.parse(
      JSON.stringify(state.hobbyistDialogData)
    );
    hobbyistDialogData[key] = value;
    if (key === 'firstName') {
      hobbyistDialogData['message'] = '';
    }
    if (key === 'lastName') {
      hobbyistDialogData['message'] = '';
    }
    if (key === 'bio') {
      hobbyistDialogData['message'] = '';
    }
    setHobbyistDialogData(hobbyistDialogData);
  };
  const setHobbyist = async (hobbyist: any) => {
    dispatch({ type: SET_GYM_MEMBER, payload: hobbyist });
  };
  const setHobbyists = async (hobbyists: any[]) => {
    dispatch({ type: SET_GYM_MEMBERS, payload: hobbyists });
  };
  const setHobbyistDialogData = async (hobbyistDialogData: any) => {
    dispatch({
      type: SET_GYM_MEMBER_DIALOG_DATA,
      payload: hobbyistDialogData,
    });
  };
  const setHobbyistDialogOpen = async (hobbyistDialogOpen: any) => {
    dispatch({
      type: SET_GYM_MEMBER_DIALOG_OPEN,
      payload: hobbyistDialogOpen,
    });
  };

  // train items and dialog --------------------------------------------
  const setTrains = async (trains: any[]) => {
    dispatch({ type: SET_EXERCISE_ITEMS, payload: trains });
  };
  const setTrainDialogDataItem = async (key: string, value: any) => {
    let trainItemDialogData = JSON.parse(
      JSON.stringify(state.trainItemDialogData)
    );
    trainItemDialogData[key] = value;
    setTrainDialogData(trainItemDialogData);
  };
  const setTrainDialogDataCategory = async (key: string) => {
    let myNewCategories = JSON.parse(
      JSON.stringify(state.trainItemDialogData.categoryJSON)
    );
    let myIndex = -1;
    let keysToClear = [
      'steam',
      'diesel',
      'electric',
      'passenger',
      'operatingCars',
      'passenger',
      'operatingCars',
      'freightCars',
      'caboose',
      'accessories',
      'sets',
    ];
    if (keysToClear.indexOf(key, 0) !== -1) {
      for (let i = 0; i < keysToClear.length; i++) {
        myIndex = myNewCategories.indexOf(keysToClear[i], 0);
        if (myIndex !== -1) {
          myNewCategories.splice(myIndex, 1);
        }
      }
    }
    myIndex = myNewCategories.indexOf(key, 0);
    if (myIndex !== -1) {
      myNewCategories.splice(myIndex, 1);
    } else {
      myNewCategories.push(key);
    }
    setTrainDialogDataItem('categoryJSON', myNewCategories);
  };
  const setTrainDialogData = async (trainItemDialogData: any) => {
    dispatch({
      type: SET_EXERCISE_ITEM_DIALOG_DATA,
      payload: trainItemDialogData,
    });
  };
  const setTrainDialogOpen = async (trainItemDialogOpen: any) => {
    dispatch({
      type: SET_EXERCISE_ITEM_DIALOG_OPEN,
      payload: trainItemDialogOpen,
    });
  };

  // trainSets and dialog -----------------------------------------------
  const setTrainSets = async (trainSets: any[]) => {
    dispatch({ type: SET_WORKOUT_ITEMS, payload: trainSets });
  };
  const setTrainSetDialogDataItem = async (
    key: string | number,
    value: any
  ) => {
    let trainSetDialogData = JSON.parse(
      JSON.stringify(state.trainSetDialogData)
    );
    trainSetDialogData[key] = value;
    setTrainSetDialogData(trainSetDialogData);
  };
  const setTrainSetDialogData = async (trainSetDialogData: any) => {
    dispatch({ type: SET_WORKOUT_DIALOG_DATA, payload: trainSetDialogData });
  };
  const setTrainSetDialogOpen = async (trainSetDialogData: any) => {
    dispatch({ type: SET_WORKOUT_DIALOG_OPEN, payload: trainSetDialogData });
  };

  // gym days and dialog -----------------------------------------------
  const setGymDays = async (gymDays: any[]) => {
    dispatch({ type: SET_GYM_DAY_ITEMS, payload: gymDays });
  };
  const setGymDayDialogDataItem = async (key: string | number, value: any) => {
    let gymDayDialogData = JSON.parse(JSON.stringify(state.gymDayDialogData));
    gymDayDialogData[key] = value;
    setGymDayDialogData(gymDayDialogData);
  };
  const setGymDayDialogData = async (gymDayDialogData: any) => {
    dispatch({ type: SET_GYM_DAY_DIALOG_DATA, payload: gymDayDialogData });
  };
  const setGymDayDialogOpen = async (gymDayDialogOpen: any) => {
    dispatch({ type: SET_GYM_DAY_DIALOG_OPEN, payload: gymDayDialogOpen });
  };

  // photos and dialog -----------------------------------------------------
  const setPhotos = async (photos: any[]) => {
    dispatch({ type: SET_PHOTOS, payload: photos });
  };
  const setPhotoDialogDataItem = async (key: string | number, value: any) => {
    let photoDialogData = JSON.parse(JSON.stringify(state.photoDialogData));
    photoDialogData[key] = value;
    setPhotoDialogData(photoDialogData);
  };
  const setPhotoDialogData = async (photoDialogData: any) => {
    dispatch({ type: SET_PHOTO_DIALOG_DATA, payload: photoDialogData });
  };
  const setPhotoDialogOpen = async (photoDialogOpen: any) => {
    dispatch({ type: SET_PHOTO_DIALOG_OPEN, payload: photoDialogOpen });
  };

  // image editor -----------------------------------------------------
  const setImageEditorDataItem = (key: string | number, value: any) => {
    let imageEditorData = JSON.parse(JSON.stringify(state.imageEditorData));
    imageEditorData[key] = value;
    setImageEditorData(imageEditorData);
  };
  const setImageEditorData = async (imageEditorData: any) => {
    dispatch({ type: SET_IMAGE_EDITOR_DATA, payload: imageEditorData });
  };

  // todays trainSets ----------------------------------------------------
  const getTodaysTrainSets = async (
    gymDays: any,
    selectedDate: Date,
    trainSets: any
  ) => {
    setLoading(true);
    let todaysGymDays = [];
    for (let j = 0; j < gymDays.length; j++) {
      if (
        validDate(
          gymDays[j].dateFrom,
          gymDays[j].dateTo,
          selectedDate,
          gymDays[j].dayJSON
        )
      ) {
        todaysGymDays.push(gymDays[j]);
      }
    }

    let trainSetIdsJSON: any[] = [];
    for (let j = 0; j < todaysGymDays.length; j++) {
      for (let k = 0; k < todaysGymDays[j].trainSetIdsJSON.length; k++) {
        trainSetIdsJSON.push(todaysGymDays[j].trainSetIdsJSON[k]);
      }
    }

    let todaysWorkOuts: any[] = [];

    for (let j = 0; j < trainSets.length; j++) {
      for (let k = 0; k < trainSetIdsJSON.length; k++) {
        if (trainSets[j].id === trainSetIdsJSON[k].toString()) {
          let trainSet = JSON.parse(JSON.stringify(trainSets[j]));
          trainSet.key = trainSet.id + k;
          todaysWorkOuts.push(trainSet);
        }
      }
    }

    setTodaysTrainSets(todaysWorkOuts);
    setLoading(false);
  };

  const setTodaysTrainSets = async (todaysTrainSets: any[]) => {
    dispatch({ type: SET_TODAYS_WORKOUTS, payload: todaysTrainSets });
  };

  // set train day -------------------------------------------
  const setTrainDay = async (trainDay: any[]) => {
    dispatch({ type: SET_EXERCISE_DAY, payload: trainDay });
  };

  const setTrainDayItem = async (
    trainId: string,
    key: string | number,
    value: any
  ) => {
    let trainDayData = JSON.parse(JSON.stringify(state.trainDay));
    if (trainDayData.id === undefined) {
      trainDayData.id =
        state.hobbyist.id +
        dateString(state.selectedDate, state.selectedDate, 'dateAsId');
    }
    if (trainDayData.dataJSON === undefined) {
      trainDayData.dataJSON = {};
    }
    if (trainDayData.dataJSON[trainId] === undefined) {
      trainDayData.dataJSON[trainId] = {};
    }
    trainDayData.dataJSON[trainId][key] = value;

    setTrainDay(trainDayData);
  };

  // set trains previous -------------------------------------------
  const setTrainsPrevious = async (trainsPrevious: any[]) => {
    dispatch({ type: SET_EXERCISES_PREVIOUS, payload: trainsPrevious });
  };

  return (
    <DataAndMethodsContext.Provider
      value={{
        apiPath: state.apiPath,
        apiName: state.apiName,

        myStates: state.myStates,

        todaysDate: state.todaysDate,
        selectedDate: state.selectedDate,

        loading: state.loading,
        loadingDialog: state.loadingDialog,
        onScreenDebugMessage: state.onScreenDebugMessage,

        signInRegDialogType: state.signInRegDialogType,
        signInRegDialogTitle: state.signInRegDialogTitle,
        authToken: state.authToken,
        idToken: state.idToken,
        customId: state.customId,
        logInType: state.logInType,

        hobbyist: state.hobbyist,
        hobbyists: state.hobbyists,
        hobbyistDialogData: state.hobbyistDialogData,
        hobbyistDialogOpen: state.hobbyistDialogOpen,

        trains: state.trains,
        trainItemDialogData: state.trainItemDialogData,
        trainItemDialogOpen: state.trainItemDialogOpen,

        trainsTableName: state.trainsTableName,
        myTrainStates: state.myTrainStates,

        trainSets: state.trainSets,
        trainSetDialogData: state.trainSetDialogData,
        trainSetDialogOpen: state.trainSetDialogOpen,

        gymDays: state.gymDays,
        gymDayDialogData: state.gymDayDialogData,
        gymDayDialogOpen: state.gymDayDialogOpen,

        photos: state.photos,
        photoDialogData: state.photoDialogData,
        photoDialogOpen: state.photoDialogOpen,

        imageEditorData: state.imageEditorData,

        todaysTrainSets: state.todaysTrainSets,

        trainDay: state.trainDay,

        trainsPrevious: state.trainsPrevious,

        setMyState,
        setMyStates,

        getTodaysTrainSets,
        setTodaysDate,
        setSelectedDate,

        setLoading,
        setLoadingDialog,

        setSignInRegDialogType,
        setSignInRegDialogTitle,
        setAuthToken,
        setIdToken,
        setCustomId,
        setLogInType,

        setHobbyist,
        setHobbyists,
        setHobbyistDialogData,
        setHobbyistDialogOpen,
        setHobbyistDialogDataItem,

        setTrains,
        setTrainDialogDataItem,
        setTrainDialogOpen,
        setTrainDialogData,
        setTrainDialogDataCategory,

        setTrainSets,
        setTrainSetDialogDataItem,
        setTrainSetDialogData,
        setTrainSetDialogOpen,

        setGymDays,
        setGymDayDialogDataItem,
        setGymDayDialogData,
        setGymDayDialogOpen,

        setPhotos,
        setPhotoDialogDataItem,
        setPhotoDialogData,
        setPhotoDialogOpen,

        setImageEditorData,
        setImageEditorDataItem,

        setTrainDay,
        setTrainDayItem,

        setTrainsPrevious,
      }}
    >
      {props.children}
    </DataAndMethodsContext.Provider>
  );
};

export default DataAndMethodsState;
