import { API } from 'aws-amplify';
import awsconfig from '../amplify-config';
import {
  trainsTableName,
  trainSetsTableName,
  hobbyistsTableName,
  apiName,
  apiPath,
  blankPlaceHolder,
} from './apiConstants';

API.configure(awsconfig);

const putItemDynamoDB = async (
  myTableName: any,
  myItem: any,
  myIdToken: any,
  myCustomId: any
): Promise<any> => {
  let myNewItem = {};
  switch (myTableName) {
    case trainsTableName:
      myNewItem = {
        id: myItem.id,
        title: (myItem.title =
          myItem.title !== '' ? myItem.title : blankPlaceHolder),
        modelNumber: (myItem.modelNumber =
          myItem.modelNumber !== '' ? myItem.modelNumber : blankPlaceHolder),
        description: (myItem.description =
          myItem.description !== '' ? myItem.description : blankPlaceHolder),
        roadName: (myItem.roadName =
          myItem.roadName !== '' ? myItem.roadName : blankPlaceHolder),
        yearBought: (myItem.yearBought =
          myItem.yearBought !== '' ? myItem.yearBought : blankPlaceHolder),
        trainLocation: (myItem.trainLocation =
          myItem.trainLocation !== ''
            ? myItem.trainLocation
            : blankPlaceHolder),
        boxLocation: (myItem.boxLocation =
          myItem.boxLocation !== '' ? myItem.boxLocation : blankPlaceHolder),
        boughtPrice: (myItem.boughtPrice =
          myItem.boughtPrice !== '' ? myItem.boughtPrice : blankPlaceHolder),
        marketPrice: (myItem.marketPrice =
          myItem.marketPrice !== '' ? myItem.marketPrice : blankPlaceHolder),
        videoUrl: (myItem.videoUrl =
          myItem.videoUrl !== '' ? myItem.videoUrl : blankPlaceHolder),
        categoryJSON: JSON.stringify(myItem.categoryJSON),
        imageUrl: (myItem.imageUrl =
          myItem.imageUrl !== '' ? myItem.imageUrl : blankPlaceHolder),
      };
      break;
    case trainSetsTableName:
      myNewItem = {
        id: myItem.id,
        title: (myItem.title =
          myItem.title !== '' ? myItem.title : blankPlaceHolder),
        trainIdsJSON: JSON.stringify(myItem.trainIdsJSON),
      };
      break;
    case hobbyistsTableName:
      myNewItem = {
        id: myItem.id,
        firstName:
          myItem.firstName !== '' ? myItem.firstName : blankPlaceHolder,
        lastName: myItem.lastName !== '' ? myItem.lastName : blankPlaceHolder,
        email: myItem.email !== '' ? myItem.email : blankPlaceHolder,
        bio: myItem.bio !== '' ? myItem.bio : blankPlaceHolder,
        trainIdsJSON: JSON.stringify(myItem.trainIdsJSON),
        friendIdsJSON: JSON.stringify(myItem.friendIdsJSON),
        gymDayIdsJSON: JSON.stringify(myItem.gymDayIdsJSON),
        trainSetIdsJSON: JSON.stringify(myItem.trainSetIdsJSON),
        dataJSON: JSON.stringify(myItem.dataJSON),
        trainDaysJSON: JSON.stringify(myItem.trainDaysJSON),
        imageUrl: myItem.imageUrl !== '' ? myItem.imageUrl : blankPlaceHolder,
      };
      break;
    default:
  }
  let myReturnObject = { err: false, payload: '' };
  try {
    const apiRequest = {
      body: {
        myMethod: 'put',
        myBody: {
          TableName: myTableName,
          Item: myNewItem,
          ReturnConsumedCapacity: 'TOTAL',
        },
        myId: myCustomId,
      },
      headers: {
        Authorization: myIdToken,
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
    };
    const data = await API.post(apiName, apiPath, apiRequest);
    myReturnObject.payload = data;
    return myReturnObject;
  } catch (err) {
    myReturnObject.err = true;
    myReturnObject.payload = (err as Error).message;
    return myReturnObject;
  }
};

export default putItemDynamoDB;
