import batchGetItemDynamoDB from '../../api/batchGetItemDynamoDB';

import {
  trainsTableName,
  projectionExpressionTrain,
  blankPlaceHolder,
} from '../../api/apiConstants';

const getBatch = async (ids: any) => {
  let trains = [];
  const data = await batchGetItemDynamoDB(
    trainsTableName,
    ids,
    projectionExpressionTrain
  );
  if (data.err) {
    return [];
  }
  trains = data.payload.Responses.trains;
  for (let i = 0; i < trains.length; i++) {
    trains[i].title =
      trains[i].title === blankPlaceHolder ? '' : trains[i].title;
    trains[i].dataJSON =
      trains[i].dataJSON === undefined
        ? JSON.parse('[]')
        : JSON.parse(trains[i].dataJSON);
    trains[i].categoryJSON =
      trains[i].categoryJSON === undefined
        ? JSON.parse('[]')
        : JSON.parse(trains[i].categoryJSON);
    trains[i].videoUrl =
      trains[i].videoUrl === blankPlaceHolder ? '' : trains[i].videoUrl;
  }
  return trains;
};

const getTrains = async (trainItemIds: any) => {
  if (trainItemIds.length === 0) {
    return [];
  }

  // get records in batches of 100
  let myIds = [];
  let currentIndex = 0;
  let nextIndex = 0;
  let myTrains: any = [];
  for (let i = 0; i < trainItemIds.length; i++) {
    myIds.push(trainItemIds[i]);
    currentIndex++;
    if (currentIndex > 99) {
      const myBatch = await getBatch(myIds);
      myIds = [];
      currentIndex = 0;
      myTrains = myTrains.concat(myBatch);
      nextIndex = i + 1;
    }
  }

  // get any leftover records
  myIds = [];
  for (let i = nextIndex; i < trainItemIds.length; i++) {
    myIds.push(trainItemIds[i]);
  }
  const myBatch = await getBatch(myIds);
  myTrains = myTrains.concat(myBatch);

  return myTrains;
};

export default getTrains;
