import putItemDynamoDB from '../../api/putItemDynamoDB';
import { hobbyistsTableName } from '../../api/apiConstants';

const createHobbyist = async (
  myEmailId: any,
  myToken: any,
  myCustomId: any
) => {
  let myHobbyist = {
    id: myEmailId,
    firstName: '',
    lastName: '',
    bio: '',
    email: myEmailId,
    imageUrl: '',
    trainIdsJSON: [],
    gymDayIdsJSON: [],
    trainSetIdsJSON: [],
    friendIdsJSON: [],
    dataJSON: [],
    trainDaysJSON: {},
  };
  const data = await putItemDynamoDB(
    hobbyistsTableName,
    myHobbyist,
    myToken,
    myCustomId
  );
  if (data.err) {
    return null;
  }
  return myHobbyist;
};

export default createHobbyist;
