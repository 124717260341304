import React, { useContext } from 'react';
import TrainCard from './TrainCard';
import DataAndMethodsContext from '../../../context/dataAndMethods/dataAndMethodsContext';
import CircularIndeterminate from '../../circularIndeterminate/CircularIndeterminate';
import getTrainsCategories from '../../../model/train/getTrainsCategories';

const Trains = () => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const { trains, myStates, loading } = dataAndMethodsContext;

  let myCategories: any = getTrainsCategories(trains);

  if (loading) {
    return <CircularIndeterminate />;
  } else {
    return (
      <div>
        {myCategories.steam.length > 0 && (
          <h3 style={{ marginTop: '1rem', textAlign: 'center' }}>
            <i className='icon-steam'></i>
            {' - '}Steam
          </h3>
        )}
        {myCategories.steam.map((Train: { id: any }) => (
          <TrainCard Train={Train} myStates={myStates} key={Train.id} />
        ))}

        {myCategories.diesel.length > 0 && (
          <h3 style={{ marginTop: '1rem', textAlign: 'center' }}>
            <i className='icon-diesel'></i>
            {' - '}Diesel
          </h3>
        )}
        {myCategories.diesel.map((Train: { id: any }) => (
          <TrainCard Train={Train} myStates={myStates} key={Train.id} />
        ))}

        {myCategories.electric.length > 0 && (
          <h3 style={{ marginTop: '1rem', textAlign: 'center' }}>
            <i className='icon-electric'></i>
            {' - '}Electric
          </h3>
        )}
        {myCategories.electric.map((Train: { id: any }) => (
          <TrainCard Train={Train} myStates={myStates} key={Train.id} />
        ))}

        {myCategories.passenger.length > 0 && (
          <h3 style={{ marginTop: '1rem', textAlign: 'center' }}>
            <i className='icon-passenger'></i>
            {' - '}Passenger
          </h3>
        )}
        {myCategories.passenger.map((Train: { id: any }) => (
          <TrainCard Train={Train} myStates={myStates} key={Train.id} />
        ))}

        {myCategories.operatingCars.length > 0 && (
          <h3 style={{ marginTop: '1rem', textAlign: 'center' }}>
            <i className='icon-freight'></i>
            {' - '}Operating Cars
          </h3>
        )}
        {myCategories.operatingCars.map((Train: { id: any }) => (
          <TrainCard Train={Train} myStates={myStates} key={Train.id} />
        ))}

        {myCategories.freightCars.length > 0 && (
          <h3 style={{ marginTop: '1rem', textAlign: 'center' }}>
            <i className='icon-freight'></i>
            {' - '}Freight Cars
          </h3>
        )}
        {myCategories.freightCars.map((Train: { id: any }) => (
          <TrainCard Train={Train} myStates={myStates} key={Train.id} />
        ))}

        {myCategories.caboose.length > 0 && (
          <h3 style={{ marginTop: '1rem', textAlign: 'center' }}>
            <i className='icon-caboose'></i>
            {' - '}Caboose
          </h3>
        )}
        {myCategories.caboose.map((Train: { id: any }) => (
          <TrainCard Train={Train} myStates={myStates} key={Train.id} />
        ))}

        {myCategories.accessories.length > 0 && (
          <h3 style={{ marginTop: '1rem', textAlign: 'center' }}>
            <i className='fa fa-industry'></i>
            {' - '}Accessories
          </h3>
        )}
        {myCategories.accessories.map((Train: { id: any }) => (
          <TrainCard Train={Train} myStates={myStates} key={Train.id} />
        ))}

        {myCategories.sets.length > 0 && (
          <h3 style={{ marginTop: '1rem', textAlign: 'center' }}>
            <i className='icon-train-set'></i>
            {' - '}Sets
          </h3>
        )}
        {myCategories.sets.map((Train: { id: any }) => (
          <TrainCard Train={Train} myStates={myStates} key={Train.id} />
        ))}

        {myCategories.notCategorized.length > 0 && (
          <h3 style={{ marginTop: '1rem', textAlign: 'center' }}>
            Not categorized
          </h3>
        )}
        {myCategories.notCategorized.map((Train: { id: any }) => (
          <TrainCard Train={Train} myStates={myStates} key={Train.id} />
        ))}
      </div>
    );
  }
};

export default Trains;
