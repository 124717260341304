import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';
import DataAndMethodsContext from '../../../context/dataAndMethods/dataAndMethodsContext';
import DeleteConfirmDialogContext from '../../../context/deleteConfirmDialog/deleteConfirmDialogContext';
import sortTrains from '../../../model/train/sortTrains';
import deleteTrain from '../../../model/train/deleteTrain';
import putHobbyist from '../../../model/hobbyist/putHobbyist';
import getTrains from '../../../model/train/getTrains';
import deleteImageAPI from '../../../model/images/deleteImageAPI';

const TrainCard = ({ Train }: any) => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const {
    trains,
    setTrainDialogData,
    setTrainDialogOpen,
    setImageEditorData,
    idToken,
    customId,
    setTrains,
    myStates,
    setHobbyist,
    hobbyist,
  } = dataAndMethodsContext;

  const imageStyle = {
    marginLeft: '.2rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '4.0rem',
    height: '4.0rem',
    borderRadius: '0.3rem',
  };

  const deleteConfirmDialogContext: any = useContext(
    DeleteConfirmDialogContext
  );
  const { setDeleteConfirmDialog } = deleteConfirmDialogContext;

  const trainListIndex = (trainId: any) => {
    for (let i = 0; i < trains.length; i++) {
      if (trainId === trains[i].id) {
        return i;
      }
    }
    return -1;
  };

  const handleClickTrainEdit = (trainId: any) => {
    let i = trainListIndex(trainId);
    if (i === -1) return;
    let train = {
      id: trains[i].id,
      title: trains[i].title,
      modelNumber: trains[i].modelNumber,
      description: trains[i].description,
      roadName: trains[i].roadName,
      yearBought: trains[i].yearBought,
      trainLocation: trains[i].trainLocation,
      boxLocation: trains[i].boxLocation,
      boughtPrice: trains[i].boughtPrice,
      marketPrice: trains[i].marketPrice,
      categoryJSON: trains[i].categoryJSON,
      videoUrl: trains[i].videoUrl,
      dialogType: 'Edit',
    };
    let myImageEditorItem = {
      imageUrl: trains[i].imageUrl,
      editMode: 'none',
      deleteFileName: '',
      width: 1,
      height: 1,
      aspectRatio: 1,
      blob: '',
      showDelete: true,
    };
    setImageEditorData(myImageEditorItem);
    setTrainDialogData(train);
    setTrainDialogOpen(true);
  };

  const handleClickTrainCopy = (trainId: any) => {
    let i = trainListIndex(trainId);
    if (i === -1) return;
    let train = {
      id: uuidv4(),
      title: trains[i].title,
      modelNumber: trains[i].modelNumber,
      description: trains[i].description,
      roadName: trains[i].roadName,
      yearBought: trains[i].yearBought,
      trainLocation: trains[i].trainLocation,
      boxLocation: trains[i].boxLocation,
      boughtPrice: trains[i].boughtPrice,
      marketPrice: trains[i].marketPrice,
      categoryJSON: trains[i].categoryJSON,
      videoUrl: trains[i].videoUrl,
      dialogType: 'Add',
    };
    let myImageEditorItem = {
      imageUrl: '',
      editMode: 'none',
      deleteFileName: '',
      width: 1,
      height: 1,
      aspectRatio: 1,
      blob: '',
      showDelete: true,
    };
    setImageEditorData(myImageEditorItem);
    setTrainDialogData(train);
    setTrainDialogOpen(true);
  };

  const loadDeleteTrainDialog = (trainId: any) => {
    for (let i = 0; i < trains.length; i++) {
      if (trainId === trains[i].id) {
        setDeleteConfirmDialog(
          true,
          trains[i].title,
          'deleteTrain',
          trainId,
          deleteTrainById
        );
        break;
      }
    }
  };

  const deleteTrainById = async (trainId: any) => {
    let myNewHobbyist = JSON.parse(JSON.stringify(hobbyist));
    myNewHobbyist.trainIdsJSON = myNewHobbyist.trainIdsJSON.filter(
      (e: any) => e !== trainId
    );
    let i = trainListIndex(trainId);
    if (i !== -1) {
      await deleteImageAPI(trains[i].imageUrl, idToken, customId);
    }
    await deleteTrain(trainId, idToken, customId);
    await putHobbyist(myNewHobbyist, idToken, customId);
    setHobbyist(myNewHobbyist);
    let myTrains = await getTrains(myNewHobbyist.trainIdsJSON);
    myTrains = await sortTrains(myTrains, myStates);
    setTrains(myTrains);
  };

  return (
    <div className='card'>
      <Grid container spacing={3}>
        <Grid item xs='auto'>
          {Train.imageUrl !== '' && (
            <img
              src={Train.imageUrl}
              alt=''
              className='round-img all-center'
              style={imageStyle}
            />
          )}
        </Grid>
        <Grid item xs>
          <h4 className={'my-train-title'}>
            <i className='fa fa-train'></i>
            {' - '}
            {Train.title}
          </h4>
          {myStates['showDescription'] && <p>{Train.description}</p>}
          <div>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => handleClickTrainEdit(Train.id)}
            >
              <i className='fas fa-edit'></i>
            </Button>
            <Button
              sx={{ marginLeft: 1 }}
              variant='outlined'
              color='primary'
              onClick={() => handleClickTrainCopy(Train.id)}
            >
              <i className='fas fa-copy'></i>
            </Button>
            <Button
              sx={{ marginLeft: 1 }}
              variant='outlined'
              color='primary'
              onClick={() => loadDeleteTrainDialog(Train.id)}
            >
              <i className='fas fa-trash'></i>
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TrainCard;
