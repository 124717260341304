import React, { useContext, useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Tooltip,
} from '@mui/material';
import DataAndMethodsContext from '../../context/dataAndMethods/dataAndMethodsContext';
import putTrain from '../../model/train/putTrain';
import sortTrains from '../../model/train/sortTrains';
import getTrains from '../../model/train/getTrains';
import putHobbyist from '../../model/hobbyist/putHobbyist';
import VideoEmbed from '../VideoEmbed';
import ImageEditor from '../imageEditor/ImageEditor';
import saveImageToDatabase from '../../model/images/saveImageToDatabase';
import TextReader from '../TextReader/TextReader';

const TrainDialog: any = () => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const [textReader, setTextReader] = useState(0);
  const [imgSrc, setImgSrc] = useState('');

  const {
    id,
    title,
    modelNumber,
    description,
    yearBought,
    trainLocation,
    boxLocation,
    boughtPrice,
    marketPrice,
    roadName,
    categoryJSON,
    videoUrl,
    dialogType,
  } = dataAndMethodsContext.trainItemDialogData;

  const {
    trainItemDialogOpen,
    setTrainDialogDataCategory,
    setTrainDialogOpen,
    setTrainDialogDataItem,
    idToken,
    customId,
    setTrains,
    hobbyist,
    setHobbyist,
    myStates,
  } = dataAndMethodsContext;

  const { deleteFileName, imageUrl, blob, editMode } =
    dataAndMethodsContext.imageEditorData;

  const handleClose = () => {
    setTrainDialogOpen(false);
  };

  const handleSave = () => {
    switch (dialogType) {
      case 'Edit':
        saveTrain();
        break;
      case 'Add':
        saveTrainAdd();
        break;
      default:
    }
    setTrainDialogOpen(false);
  };

  const saveTrain = async () => {
    let newTrain: any = {};
    newTrain.id = id;
    newTrain.title = title;
    newTrain.modelNumber = modelNumber;
    newTrain.description = description;
    newTrain.yearBought = yearBought;
    newTrain.trainLocation = trainLocation;
    newTrain.boxLocation = boxLocation;
    newTrain.boughtPrice = boughtPrice;
    newTrain.marketPrice = marketPrice;
    newTrain.roadName = roadName;
    newTrain.categoryJSON = categoryJSON;
    newTrain.videoUrl = videoUrl;
    newTrain.imageUrl = imageUrl;
    await saveImageToDatabase(
      deleteFileName,
      imageUrl,
      blob,
      editMode,
      idToken,
      customId
    );
    await putTrain(newTrain, idToken, customId);
    let myTrains = await getTrains(hobbyist.trainIdsJSON);
    myTrains = await sortTrains(myTrains, myStates);
    setTrains(myTrains);
  };

  const saveTrainAdd = async () => {
    let newTrain: any = {};
    newTrain.id = id;
    newTrain.title = title;
    newTrain.modelNumber = modelNumber;
    newTrain.description = description;
    newTrain.yearBought = yearBought;
    newTrain.trainLocation = trainLocation;
    newTrain.boxLocation = boxLocation;
    newTrain.boughtPrice = boughtPrice;
    newTrain.marketPrice = marketPrice;
    newTrain.roadName = roadName;
    newTrain.categoryJSON = categoryJSON;
    newTrain.videoUrl = videoUrl;
    newTrain.imageUrl = imageUrl;
    await saveImageToDatabase(
      deleteFileName,
      imageUrl,
      blob,
      editMode,
      idToken,
      customId
    );
    await putTrain(newTrain, idToken, customId);
    let myNewHobbyist = JSON.parse(JSON.stringify(hobbyist));
    myNewHobbyist.trainIdsJSON.push(id);
    await putHobbyist(myNewHobbyist, idToken, customId);
    setHobbyist(myNewHobbyist);
    let myTrains = await getTrains(myNewHobbyist.trainIdsJSON);
    myTrains = await sortTrains(myTrains, myStates);
    setTrains(myTrains);
  };

  const changeItem = (e: any, itemName: string) => {
    setTrainDialogDataItem(itemName, e.target.value);
  };

  const changeFieldOCR = (e: any, itemName: string) => {
    setTrainDialogDataItem(itemName, e);
  };

  const checkIfPresent = (value: any) => {
    if (categoryJSON) {
      if (categoryJSON.indexOf(value) !== -1) {
        return true;
      }
    }
    return false;
  };

  const onClickSetTextReader = (item: number) => {
    if (item === textReader) {
      setTextReader(0);
    } else {
      setTextReader(item);
    }
  };

  return (
    <div>
      <Dialog
        open={trainItemDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          {dialogType + ' train'}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={11}>
              <TextField
                id='title'
                label='Title'
                type='text'
                fullWidth
                variant='filled'
                size='small'
                value={title}
                onChange={(e) => changeItem(e, 'title')}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                sx={{ marginTop: 1.5 }}
                color={textReader === 1 ? 'inherit' : 'default'}
                component='span'
                onClick={() => onClickSetTextReader(1)}
              >
                <i className='fas fa-file-image'></i>
              </IconButton>
            </Grid>
          </Grid>
          {textReader === 1 && (
            <TextReader
              fieldOCR={'title'}
              changeFieldOCR={changeFieldOCR}
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
            ></TextReader>
          )}

          <Grid container>
            <Grid item xs={11}>
              <TextField
                id='modelNumber'
                label='Model Number'
                type='text'
                fullWidth
                variant='filled'
                size='small'
                value={modelNumber}
                onChange={(e) => changeItem(e, 'modelNumber')}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                sx={{ marginTop: 1.5 }}
                color={textReader === 2 ? 'inherit' : 'default'}
                component='span'
                onClick={() => onClickSetTextReader(2)}
              >
                <i className='fas fa-file-image'></i>
              </IconButton>
            </Grid>
          </Grid>
          {textReader === 2 && (
            <TextReader
              fieldOCR={'modelNumber'}
              changeFieldOCR={changeFieldOCR}
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
            ></TextReader>
          )}

          <Grid container>
            <Grid item xs={11}>
              <TextField
                id='roadName'
                label='RoadName'
                type='text'
                fullWidth
                variant='filled'
                size='small'
                value={roadName}
                onChange={(e) => changeItem(e, 'roadName')}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                sx={{ marginTop: 1.5 }}
                color={textReader === 3 ? 'inherit' : 'default'}
                component='span'
                onClick={() => onClickSetTextReader(3)}
              >
                <i className='fas fa-file-image'></i>
              </IconButton>
            </Grid>
          </Grid>
          {textReader === 3 && (
            <TextReader
              fieldOCR={'roadName'}
              changeFieldOCR={changeFieldOCR}
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
            ></TextReader>
          )}

          <Grid container>
            <Grid item xs={11}>
              <TextField
                id='description'
                label='Description'
                type='text'
                fullWidth
                variant='filled'
                multiline={true}
                minRows='3'
                value={description}
                onChange={(e) => changeItem(e, 'description')}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                sx={{ marginTop: 5 }}
                color={textReader === 4 ? 'inherit' : 'default'}
                component='span'
                onClick={() => onClickSetTextReader(4)}
              >
                <i className='fas fa-file-image'></i>
              </IconButton>
            </Grid>
          </Grid>

          {textReader === 4 && (
            <TextReader
              fieldOCR={'description'}
              changeFieldOCR={changeFieldOCR}
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
            ></TextReader>
          )}

          <TextField
            id='yearBought'
            label='Year Bought'
            variant='filled'
            type='date'
            value={yearBought}
            onChange={(e) => changeItem(e, 'yearBought')}
          />
          <TextField
            id='trainLocation'
            label='Train Location'
            type='text'
            fullWidth
            variant='filled'
            size='small'
            value={trainLocation}
            onChange={(e) => changeItem(e, 'trainLocation')}
          />
          <TextField
            id='boxLocation'
            label='Box Location'
            type='text'
            fullWidth
            variant='filled'
            size='small'
            value={boxLocation}
            onChange={(e) => changeItem(e, 'boxLocation')}
          />
          <TextField
            id='boughtPrice'
            label='Bought Price'
            type='number'
            fullWidth
            variant='filled'
            size='small'
            value={boughtPrice}
            onChange={(e) => changeItem(e, 'boughtPrice')}
          />
          <TextField
            id='marketPrice'
            label='Market Price'
            type='number'
            fullWidth
            variant='filled'
            size='small'
            value={marketPrice}
            onChange={(e) => changeItem(e, 'marketPrice')}
          />
          <p>Train Category</p>
          <Toolbar>
            <div>
              <Tooltip title='Steam'>
                <IconButton
                  aria-label=''
                  color={checkIfPresent('steam') ? 'inherit' : 'default'}
                  onClick={() => setTrainDialogDataCategory('steam')}
                >
                  <i className='icon-steam'></i>
                </IconButton>
              </Tooltip>

              <Tooltip title='Diesel'>
                <IconButton
                  aria-label=''
                  color={checkIfPresent('diesel') ? 'inherit' : 'default'}
                  onClick={() => setTrainDialogDataCategory('diesel')}
                >
                  <i className='icon-diesel'></i>
                </IconButton>
              </Tooltip>

              <Tooltip title='Electric'>
                <IconButton
                  aria-label=''
                  color={checkIfPresent('electric') ? 'inherit' : 'default'}
                  onClick={() => setTrainDialogDataCategory('electric')}
                >
                  <i className='icon-electric'></i>
                </IconButton>
              </Tooltip>

              <Tooltip title='Passenger'>
                <IconButton
                  aria-label=''
                  color={checkIfPresent('passenger') ? 'inherit' : 'default'}
                  onClick={() => setTrainDialogDataCategory('passenger')}
                >
                  <i className='icon-passenger'></i>
                </IconButton>
              </Tooltip>

              <Tooltip title='Operating Cars'>
                <IconButton
                  aria-label=''
                  color={
                    checkIfPresent('operatingCars') ? 'inherit' : 'default'
                  }
                  onClick={() => setTrainDialogDataCategory('operatingCars')}
                >
                  <i className='icon-freight'></i>
                </IconButton>
              </Tooltip>

              <Tooltip title='Freight Cars'>
                <IconButton
                  aria-label=''
                  color={checkIfPresent('freightCars') ? 'inherit' : 'default'}
                  onClick={() => setTrainDialogDataCategory('freightCars')}
                >
                  <i className='icon-freight'></i>
                </IconButton>
              </Tooltip>

              <Tooltip title='Caboose'>
                <IconButton
                  aria-label=''
                  color={checkIfPresent('caboose') ? 'inherit' : 'default'}
                  onClick={() => setTrainDialogDataCategory('caboose')}
                >
                  <i className='icon-caboose'></i>
                </IconButton>
              </Tooltip>

              <Tooltip title='Accessories'>
                <IconButton
                  aria-label=''
                  color={checkIfPresent('accessories') ? 'inherit' : 'default'}
                  onClick={() => setTrainDialogDataCategory('accessories')}
                >
                  <i className='fa fa-industry'></i>
                </IconButton>
              </Tooltip>

              <Tooltip title='Sets'>
                <IconButton
                  aria-label=''
                  color={checkIfPresent('sets') ? 'inherit' : 'default'}
                  onClick={() => setTrainDialogDataCategory('sets')}
                >
                  <i className='icon-train-set'></i>
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <TextField
            id='videoUrl'
            label='Video Url'
            type='text'
            fullWidth
            variant='filled'
            multiline={false}
            size='small'
            value={videoUrl}
            onChange={(e) => changeItem(e, 'videoUrl')}
          />
          <VideoEmbed embedId={videoUrl} />
          <p>Train Image</p>
          <ImageEditor />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancel
          </Button>
          <Button onClick={() => handleSave()} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TrainDialog;
