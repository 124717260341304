import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteConfirmDialogContext from '../../context/deleteConfirmDialog/deleteConfirmDialogContext';

const DeleteConfirmDialog: any = () => {
  const deleteConfirmDialogContext: any = useContext(
    DeleteConfirmDialogContext
  );
  const { deleteConfirmDialog, closeDialog, deleteFunction } =
    deleteConfirmDialogContext;

  const [confirmMessage, setConfirmMessage] = useState('');

  const chooseDelete = () => {
    if (deleteConfirmDialog.dialogType === 'deleteTrain') {
      deleteFunction(deleteConfirmDialog.index);
      setConfirmMessage('');
    }
    if (deleteConfirmDialog.dialogType === 'deleteTrainSet') {
      deleteFunction(deleteConfirmDialog.index);
      setConfirmMessage('');
    }
    if (deleteConfirmDialog.dialogType === 'deleteGymDay') {
      deleteFunction(deleteConfirmDialog.index);
      setConfirmMessage('');
    }
    if (deleteConfirmDialog.dialogType === 'deletePhoto') {
      deleteFunction(deleteConfirmDialog.index);
      setConfirmMessage('');
    }
    if (deleteConfirmDialog.dialogType === 'deleteTrainDay') {
      deleteFunction(deleteConfirmDialog.index);
      setConfirmMessage('');
    }
  };

  const chooseClose = () => {
    closeDialog();
    setConfirmMessage('');
  };

  return (
    deleteConfirmDialog != null && (
      <div>
        <Dialog
          open={deleteConfirmDialog.dialogOpen}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          {deleteConfirmDialog.dialogType === 'deleteTrain' && (
            <DialogTitle id='alert-dialog-title'>
              <i className='fas fa-exclamation-triangle'></i>
              {'  Delete train item warning'}
            </DialogTitle>
          )}
          {deleteConfirmDialog.dialogType === 'deleteTrainSet' && (
            <DialogTitle id='alert-dialog-title'>
              <i className='fas fa-exclamation-triangle'></i>
              {'  Delete trainSet warning'}
            </DialogTitle>
          )}
          {deleteConfirmDialog.dialogType === 'deleteGymDay' && (
            <DialogTitle id='alert-dialog-title'>
              <i className='fas fa-exclamation-triangle'></i>
              {'  Delete gym day warning'}
            </DialogTitle>
          )}
          {deleteConfirmDialog.dialogType === 'deletePhoto' && (
            <DialogTitle id='alert-dialog-title'>
              <i className='fas fa-exclamation-triangle'></i>
              {'  Delete photo warning'}
            </DialogTitle>
          )}
          {deleteConfirmDialog.dialogType === 'deleteTrainDay' && (
            <DialogTitle id='alert-dialog-title'>
              <i className='fas fa-exclamation-triangle'></i>
              {'  Delete train day warning'}
            </DialogTitle>
          )}
          <DialogContent>
            {deleteConfirmDialog.dialogType === 'deleteTrain' && (
              <DialogContentText id='alert-dialog-description'>
                {`You about to delete `}
                <strong>{deleteConfirmDialog.name}</strong>
                {`.  This process is irreversible are you sure?`}
              </DialogContentText>
            )}
            {deleteConfirmDialog.dialogType === 'deleteTrainSet' && (
              <DialogContentText id='alert-dialog-description'>
                {`You about to delete `}
                <strong>{deleteConfirmDialog.name}</strong>
                {`.  This process is irreversible are you sure?`}
              </DialogContentText>
            )}
            {deleteConfirmDialog.dialogType === 'deleteGymDay' && (
              <DialogContentText id='alert-dialog-description'>
                {`You about to delete `}
                <strong>{deleteConfirmDialog.name}</strong>
                {`.  This process is irreversible are you sure?`}
              </DialogContentText>
            )}
            {deleteConfirmDialog.dialogType === 'deletePhoto' && (
              <DialogContentText id='alert-dialog-description'>
                {`You about to delete `}
                <strong>{deleteConfirmDialog.name}</strong>
                {`.  This process is irreversible are you sure?`}
              </DialogContentText>
            )}
            {deleteConfirmDialog.dialogType === 'deleteTrainDay' && (
              <DialogContentText id='alert-dialog-description'>
                {`You about to delete `}
                <strong>{deleteConfirmDialog.name}</strong>
                {`.  This process is irreversible are you sure?`}
              </DialogContentText>
            )}
            <p>{confirmMessage}</p>
          </DialogContent>
          <DialogActions>
            <Button color='secondary' onClick={() => chooseClose()}>
              CANCEL
            </Button>
            <Button color='primary' onClick={() => chooseDelete()}>
              DELETE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};

export default DeleteConfirmDialog;
