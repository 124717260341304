import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';
import DataAndMethodsContext from '../../../context/dataAndMethods/dataAndMethodsContext';
import DeleteConfirmDialogContext from '../../../context/deleteConfirmDialog/deleteConfirmDialogContext';
import getTrainSetsFromIds from '../../../model/trainSet/getTrainSetsFromIds';
import deleteTrainSet from '../../../model/trainSet/deleteTrainSet';
import putHobbyist from '../../../model/hobbyist/putHobbyist';
import sortTrainSets from '../../../model/trainSet/sortTrainSets';

const TrainSetCard: any = ({ TrainSet }: any) => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const {
    hobbyist,
    trainSets,
    setTrainSetDialogData,
    setTrainSetDialogOpen,
    idToken,
    customId,
    setHobbyist,
    myStates,
    setTrainSets,
  } = dataAndMethodsContext;

  const deleteConfirmDialogContext: any = useContext(
    DeleteConfirmDialogContext
  );
  const { setDeleteConfirmDialog } = deleteConfirmDialogContext;

  const TrainSetEditClick = (trainSetId: any) => {
    for (let i = 0; i < trainSets.length; i++) {
      if (trainSetId === trainSets[i].id) {
        let myEditItem = {
          id: trainSets[i].id,
          title: trainSets[i].title,
          trainIdsJSON: trainSets[i].trainIdsJSON,
          dialogType: 'Edit',
        };
        setTrainSetDialogData(myEditItem);
        setTrainSetDialogOpen(true);
        break;
      }
    }
  };

  const TrainSetCopyClick = (trainSetId: any) => {
    for (let i = 0; i < trainSets.length; i++) {
      if (trainSetId === trainSets[i].id) {
        let myEditItem = {
          id: uuidv4(),
          title: trainSets[i].title,
          trainIdsJSON: trainSets[i].trainIdsJSON,
          dialogType: 'Add',
        };
        setTrainSetDialogData(myEditItem);
        setTrainSetDialogOpen(true);
        break;
      }
    }
  };

  const deleteMenuClick = (trainSetId: any) => {
    for (let i = 0; i < trainSets.length; i++) {
      if (trainSetId === trainSets[i].id) {
        setDeleteConfirmDialog(
          true,
          trainSets[i].title,
          'deleteTrainSet',
          trainSetId,
          deleteTrainSetById
        );
        break;
      }
    }
  };

  const deleteTrainSetById = async (trainSetId: any) => {
    let myNewHobbyist = JSON.parse(JSON.stringify(hobbyist));
    myNewHobbyist.trainSetIdsJSON = myNewHobbyist.trainSetIdsJSON.filter(
      (e: any) => e !== trainSetId
    );
    await deleteTrainSet(trainSetId, idToken, customId);
    await putHobbyist(myNewHobbyist, idToken, customId);
    setHobbyist(myNewHobbyist);
    let myTrainSets = await getTrainSetsFromIds(myNewHobbyist.trainSetIdsJSON);
    myTrainSets = await sortTrainSets(myTrainSets, myStates);
    setTrainSets(myTrainSets);
  };

  return (
    <div className='card'>
      <h4>{TrainSet.title}</h4>
      <div>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => TrainSetEditClick(TrainSet.id)}
        >
          <i className='fas fa-edit'></i>
        </Button>
        <Button
          sx={{ marginLeft: 1 }}
          variant='outlined'
          color='primary'
          onClick={() => TrainSetCopyClick(TrainSet.id)}
        >
          <i className='fas fa-copy'></i>
        </Button>
        <Button
          sx={{ marginLeft: 1 }}
          variant='outlined'
          color='primary'
          onClick={() => deleteMenuClick(TrainSet.id)}
        >
          <i className='fas fa-trash'></i>
        </Button>
      </div>
    </div>
  );
};

export default TrainSetCard;
