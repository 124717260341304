import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
      },
    },
  },
  palette: {
    primary: {
      light: '#FFCCBC',
      main: '#FF5722',
      dark: '#E64A19',
      contrastText: '#fff',
    },
    secondary: {
      light: '#637bfe',
      main: '#3d5afe',
      dark: '#2a3eb1',
      contrastText: '#fff',
    },
  },
});

// declare module '@mui/material/styles' {
//   interface Theme {
//     status: {
//       danger: React.CSSProperties['color'];
//     };
//   }

//   interface Palette {
//     neutral: Palette['primary'];
//   }
//   interface PaletteOptions {
//     neutral: PaletteOptions['primary'];
//   }

//   interface PaletteColor {
//     darker?: string;
//   }
//   interface SimplePaletteColorOptions {
//     darker?: string;
//   }
//   interface ThemeOptions {
//     status: {
//       danger: React.CSSProperties['color'];
//     };
//   }
// }

/* Palette generated by Material Palette - materialPalette.com/deep-orange/cyan */

// .dark-primary-color    { background: #E64A19; }
// .default-primary-color { background: #FF5722; }
// .light-primary-color   { background: #FFCCBC; }
// .text-primary-color    { color: #FFFFFF; }
// .accent-color          { background: #00BCD4; }
// .primary-text-color    { color: #212121; }
// .secondary-text-color  { color: #757575; }
// .divider-color         { border-color: #BDBDBD; }

export default theme;
