import getItemDynamoDB from '../../api/getItemDynamoDB';
import { hobbyistsTableName, blankPlaceHolder } from '../../api/apiConstants';

const getHobbyist = async (myEmailId: any, myToken: any, myCustomId: any) => {
  const data = await getItemDynamoDB(
    hobbyistsTableName,
    myEmailId,
    myToken,
    myCustomId
  );
  if (data.err) {
    return null;
  }
  let myHobbyist = data.payload.Item;
  if (myHobbyist === undefined) {
    return null;
  }
  myHobbyist.firstName =
    myHobbyist.firstName === blankPlaceHolder ? '' : myHobbyist.firstName;
  myHobbyist.lastName =
    myHobbyist.lastName === blankPlaceHolder ? '' : myHobbyist.lastName;
  myHobbyist.email =
    myHobbyist.email === blankPlaceHolder ? '' : myHobbyist.email;
  myHobbyist.bio = myHobbyist.bio === blankPlaceHolder ? '' : myHobbyist.bio;
  myHobbyist.trainIdsJSON =
    myHobbyist.trainIdsJSON === undefined
      ? JSON.parse('[]')
      : JSON.parse(myHobbyist.trainIdsJSON);
  myHobbyist.friendIdsJSON =
    myHobbyist.friendIdsJSON === undefined
      ? JSON.parse('[]')
      : JSON.parse(myHobbyist.friendIdsJSON);
  myHobbyist.gymDayIdsJSON =
    myHobbyist.gymDayIdsJSON === undefined
      ? JSON.parse('[]')
      : JSON.parse(myHobbyist.gymDayIdsJSON);
  myHobbyist.trainSetIdsJSON =
    myHobbyist.trainSetIdsJSON === undefined
      ? JSON.parse('[]')
      : JSON.parse(myHobbyist.trainSetIdsJSON);
  myHobbyist.dataJSON =
    myHobbyist.dataJSON === undefined
      ? JSON.parse('[]')
      : JSON.parse(myHobbyist.dataJSON);
  myHobbyist.trainDaysJSON =
    myHobbyist.trainDaysJSON === undefined
      ? JSON.parse('[]')
      : JSON.parse(myHobbyist.trainDaysJSON);
  myHobbyist.imageUrl =
    myHobbyist.imageUrl === blankPlaceHolder ? '' : myHobbyist.imageUrl;
  return myHobbyist;
};

export default getHobbyist;
