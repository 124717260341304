import batchGetItemDynamoDB from '../../api/batchGetItemDynamoDB';

import {
  trainSetsTableName,
  projectionExpressionTrainSet,
  blankPlaceHolder,
} from '../../api/apiConstants';

const getBatch = async (myIds: any) => {
  let myTrainSets = [];
  const data = await batchGetItemDynamoDB(
    trainSetsTableName,
    myIds,
    projectionExpressionTrainSet
  );
  if (data.err) {
    return [];
  }

  myTrainSets = data.payload.Responses.trainSets;
  for (let i = 0; i < myTrainSets.length; i++) {
    myTrainSets[i].title =
      myTrainSets[i].title === blankPlaceHolder ? '' : myTrainSets[i].title;
    myTrainSets[i].trainIdsJSON = JSON.parse(myTrainSets[i].trainIdsJSON);
  }

  return myTrainSets;
};

const getTrainSetsFromIds = async (TrainSetsIds: any) => {
  if (TrainSetsIds.length === 0) {
    return [];
  }
  let myTrainSets: any = [];

  // get records in batches of 100
  let myIds = [];
  let currentIndex = 0;
  let nextIndex = 0;
  for (let i = 0; i < TrainSetsIds.length; i++) {
    myIds.push(TrainSetsIds[i]);
    currentIndex++;
    if (currentIndex > 99) {
      const myBatch = await getBatch(myIds);
      myIds = [];
      currentIndex = 0;
      myTrainSets = myTrainSets.concat(myBatch);
      nextIndex = i + 1;
    }
  }

  // get any leftover records
  myIds = [];
  for (let i = nextIndex; i < TrainSetsIds.length; i++) {
    myIds.push(TrainSetsIds[i]);
  }
  const myBatch = await getBatch(myIds);
  myTrainSets = myTrainSets.concat(myBatch);

  return myTrainSets;
};

export default getTrainSetsFromIds;
