import React, { useState, useRef } from 'react';
import Tesseract from 'tesseract.js';
import { IconButton, Slider, Box, Grid } from '@mui/material';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

import 'react-image-crop/dist/ReactCrop.css';
import resizeImageFile from '../../model/images/resizeImageFile';

const TextReader: any = (props: {
  fieldOCR: any;
  changeFieldOCR: any;
  imgSrc: any;
  setImgSrc: any;
}) => {
  const { fieldOCR, changeFieldOCR, imgSrc, setImgSrc } = props;
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [rotate, setRotate] = useState(0);
  const [rotateSlider, setRotateSlider] = useState(0);

  const scale = 1;

  async function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);

      try {
        const file = e.target.files[0];
        const image = await resizeImageFile(file);
        setImgSrc(image);
        console.log(image);
      } catch (err) {
        console.log(err);
      }
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {}

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const reconizeText = async () => {
    try {
      const myCanvas: HTMLCanvasElement = document.getElementById(
        'previewCanvas'
      ) as HTMLCanvasElement;
      const myImage = myCanvas.toDataURL('image/png');
      Tesseract.recognize(myImage, 'eng', {
        logger: (m) => console.log(m),
      }).then(({ data: { text } }) => {
        changeFieldOCR(text, fieldOCR);
      });
    } catch (error) {}
  };

  const handleRotateChange = (event: any) => {
    setRotateSlider(event.target.value);
    setRotate(event.target.value / 10);
  };

  return (
    <>
      {' '}
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <input
            accept='image/*'
            hidden
            id='raised-button-file'
            multiple
            type='file'
            onChange={(e) => onSelectFile(e)}
          />
          <label htmlFor='raised-button-file'>
            <IconButton component='span'>
              <i className='fas fa-file-image'></i>
            </IconButton>
          </label>
        </Grid>
        <Grid item xs={6}>
          <Slider
            aria-label='Rotation'
            defaultValue={0}
            min={-150}
            max={150}
            step-={1}
            color='secondary'
            value={rotateSlider}
            onChange={handleRotateChange}
          />
        </Grid>
        <Grid item>
          <IconButton component='span' onClick={() => reconizeText()}>
            <i className='fas fa-font'></i>
          </IconButton>
        </Grid>
      </Grid>
      {!!imgSrc && (
        <>
          <ReactCrop
            crop={crop}
            style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={undefined}
          >
            <img
              ref={imgRef}
              alt='Crop me'
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </>
      )}
      <div>
        {!!completedCrop && (
          <>
            <canvas
              hidden
              id={'previewCanvas'}
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default TextReader;
