const getTrainsCategories = (trains: any[]) => {
  let categories: any = {
    steam: [],
    diesel: [],
    electric: [],
    passenger: [],
    operatingCars: [],
    freightCars: [],
    caboose: [],
    accessories: [],
    sets: [],
    notCategorized: [],
  };
  let foundItem = false;
  for (let i = 0; i < trains.length; i++) {
    foundItem = false;
    if (trains[i].categoryJSON.indexOf('steam') !== -1) {
      categories.steam.push(trains[i]);
      foundItem = true;
    }

    if (trains[i].categoryJSON.indexOf('diesel') !== -1) {
      categories.diesel.push(trains[i]);
      foundItem = true;
    }

    if (trains[i].categoryJSON.indexOf('electric') !== -1) {
      categories.electric.push(trains[i]);
      foundItem = true;
    }

    if (trains[i].categoryJSON.indexOf('passenger') !== -1) {
      categories.passenger.push(trains[i]);
      foundItem = true;
    }

    if (trains[i].categoryJSON.indexOf('operatingCars') !== -1) {
      categories.operatingCars.push(trains[i]);
      foundItem = true;
    }

    if (trains[i].categoryJSON.indexOf('freightCars') !== -1) {
      categories.freightCars.push(trains[i]);
      foundItem = true;
    }

    if (trains[i].categoryJSON.indexOf('caboose') !== -1) {
      categories.caboose.push(trains[i]);
      foundItem = true;
    }

    if (trains[i].categoryJSON.indexOf('accessories') !== -1) {
      categories.accessories.push(trains[i]);
      foundItem = true;
    }

    if (trains[i].categoryJSON.indexOf('sets') !== -1) {
      categories.sets.push(trains[i]);
      foundItem = true;
    }

    if (!foundItem) {
      categories.notCategorized.push(trains[i]);
    }
  }
  return categories;
};

export default getTrainsCategories;
