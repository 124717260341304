import React, { useContext } from 'react';
import TrainSetCard from './TrainSetCard';
import DataAndMethodsContext from '../../../context/dataAndMethods/dataAndMethodsContext';
import CircularIndeterminate from '../../circularIndeterminate/CircularIndeterminate';

const TrainSets = () => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const { trainSets, myStates, loading } = dataAndMethodsContext;

  if (loading) {
    return <CircularIndeterminate />;
  } else {
    return trainSets.map((TrainSet: { id: React.Key | null | undefined }) => (
      <TrainSetCard TrainSet={TrainSet} myStates={myStates} key={TrainSet.id} />
    ));
  }
};

export default TrainSets;
