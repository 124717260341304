import React, { useContext, useState, useCallback, useRef } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from '@mui/material/Button';
import DataAndMethodsContext from '../../context/dataAndMethods/dataAndMethodsContext';
import convertFileToDataUrl from '../../model/images/convertFileToDataUrl';
import downloadImageAPI from '../../model/images/downloadImageAPI';
import convertDataUrlToBlob from '../../model/images/convertDataUrlToBlob';
import CircularIndeterminate from '../circularIndeterminate/CircularIndeterminate';

import { v4 as uuidv4 } from 'uuid';

import { blankImage, imagePath } from '../../api/apiConstants';
import resizeImageFile from '../../model/images/resizeImageFile';
import { useDebounceEffect } from '../TextReader/useDebounceEffect';
import { canvasPreview } from '../TextReader/canvasPreview';

const ImageEditor = () => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [imgSrc, setImgSrc] = useState('');

  const rotate = 0;
  const scale = 1;

  const {
    idToken,
    customId,
    setLoadingDialog,
    loadingDialog,
    setImageEditorDataItem,
    setImageEditorData,
  } = dataAndMethodsContext;

  const { imageUrl, editMode, aspectRatio, blob, showDelete } =
    dataAndMethodsContext.imageEditorData;

  const downloadImageFromUrl = async () => {
    setLoadingDialog(true);
    let myDataUrl: any = await downloadImageAPI(imageUrl, idToken, customId);
    setImgSrc(myDataUrl);
    let myBlob = await convertDataUrlToBlob(myDataUrl, 'newFile.jpeg');
    let myImageEditorData = JSON.parse(
      JSON.stringify(dataAndMethodsContext.imageEditorData)
    );
    myImageEditorData.blob = myBlob;
    myImageEditorData.editMode = 'edit';
    myImageEditorData.saveFile = true;
    setImageEditorData(myImageEditorData);
    setLoadingDialog(false);
  };

  async function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      let myImageEditorData = JSON.parse(
        JSON.stringify(dataAndMethodsContext.imageEditorData)
      );
      if (imageUrl !== blankImage) {
        myImageEditorData.deleteFileName = '';
      }

      try {
        const file = e.target.files[0];
        const image: any = await resizeImageFile(file);
        setImgSrc(image);
        console.log(image);
      } catch (err) {
        console.log(err);
      }

      if (imageUrl === blankImage || imageUrl === '') {
        let myNewId = uuidv4();
        myImageEditorData.imageUrl = imagePath + myNewId + '.jpg';
      }

      myImageEditorData.editMode = 'edit';
      myImageEditorData.saveFile = true;
      await setImageEditorData(myImageEditorData);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {}

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleEditOrCrop = async () => {
    switch (editMode) {
      case 'none':
        downloadImageFromUrl();
        setImageEditorDataItem('editMode', 'edit');
        setCrop(undefined);
        break;
      case 'edit':
        try {
          setImgSrc(window.URL.createObjectURL(blob));
          setCrop(undefined);
          setImageEditorDataItem('saveFile', true);
        } catch (error) {
          console.log(error);
        }
        break;
      default:
        break;
    }
  };

  const cropSize = async (mySize: string) => {
    let myImageEditorData = JSON.parse(
      JSON.stringify(dataAndMethodsContext.imageEditorData)
    );
    switch (mySize) {
      case '1_1':
        myImageEditorData.width = 1;
        myImageEditorData.height = 1;
        myImageEditorData.aspectRatio = 1;
        break;
      case '4_3':
        myImageEditorData.width = 4;
        myImageEditorData.height = 3;
        myImageEditorData.aspectRatio = 4 / 3;
        break;
      case '3_4':
        myImageEditorData.width = 3;
        myImageEditorData.height = 4;
        myImageEditorData.aspectRatio = 3 / 4;
        break;
      case '16_9':
        myImageEditorData.width = 16;
        myImageEditorData.height = 9;
        myImageEditorData.aspectRatio = 16 / 9;
        break;
      default:
    }
    await setImageEditorData(myImageEditorData);
    setCrop(undefined);
  };

  const handleDelete = async () => {
    let myImageEditorData = JSON.parse(
      JSON.stringify(dataAndMethodsContext.imageEditorData)
    );
    if (myImageEditorData.imageUrl !== blankImage) {
      myImageEditorData.deleteFileName = myImageEditorData.imageUrl;
    }
    myImageEditorData.imageUrl = blankImage;
    myImageEditorData.editMode = 'none';
    await setImageEditorData(myImageEditorData);
  };

  const myTextStyle = {
    fontSize: '1.5rem',
    color: 'primary',
  };

  const myEditCropIcon =
    editMode === 'none' ? 'fas fa-edit' : 'fas fa-crop-alt';
  const canEditImages =
    imageUrl === blankImage || imageUrl === '' ? false : true;

  return (
    <div>
      {loadingDialog && <CircularIndeterminate />}
      <p></p>
      <input
        accept='image/*'
        hidden
        id='raised-button-file'
        multiple
        type='file'
        onChange={(e) => onSelectFile(e)}
      />
      <label htmlFor='raised-button-file'>
        <Button component='span' style={myTextStyle}>
          <i className='fas fa-file-upload'></i>
        </Button>
      </label>
      {canEditImages && (
        <Button onClick={() => handleEditOrCrop()} style={myTextStyle}>
          <i className={myEditCropIcon}></i>
        </Button>
      )}
      {canEditImages && showDelete && (
        <Button onClick={() => handleDelete()} style={myTextStyle}>
          <i className='fas fa-trash'></i>
        </Button>
      )}
      {editMode === 'edit' && !showDelete && (
        <Button onClick={() => cropSize('1_1')} style={myTextStyle}>
          1:1
        </Button>
      )}
      {editMode === 'edit' && !showDelete && (
        <Button onClick={() => cropSize('3_4')} style={myTextStyle}>
          3:4
        </Button>
      )}
      {editMode === 'edit' && !showDelete && (
        <Button onClick={() => cropSize('4_3')} style={myTextStyle}>
          4:3
        </Button>
      )}
      {editMode === 'edit' && !showDelete && (
        <Button onClick={() => cropSize('16_9')} style={myTextStyle}>
          16:9
        </Button>
      )}
      {!loadingDialog && editMode === 'none' && imageUrl !== undefined && (
        <img
          style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
          src={imageUrl}
          alt=''
        />
      )}
      {imgSrc && editMode !== 'none' && (
        <ReactCrop
          style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          aspect={aspectRatio}
          onComplete={(c) => setCompletedCrop(c)}
        >
          <img
            ref={imgRef}
            alt='Crop me'
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      <div>
        {!!completedCrop && (
          <>
            <canvas
              hidden
              id={'previewCanvas'}
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ImageEditor;
