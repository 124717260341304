import React, { useContext } from 'react';
import TrainCardTrainSet from './TrainSetTrainCard';
import DataAndMethodsContext from '../../../context/dataAndMethods/dataAndMethodsContext';

const TrainSetTrains = (TrainSetId: any) => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const { trains, myStates } = dataAndMethodsContext;

  return trains.map((Train: { id: any }) => (
    <TrainCardTrainSet
      Train={Train}
      myStates={myStates}
      isInList={true}
      TrainId={Train.id}
      key={Train.id}
    />
  ));
};

export default TrainSetTrains;
