import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import DataAndMethodsContext from '../../context/dataAndMethods/dataAndMethodsContext';
import DefaultTopToolBar from '../../components/toolBars/DefaultTopToolBar';
import SignedInTopToolBar from '../../components/toolBars/SignedInTopToolBar';

const TopNavBar = () => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const { logInType } = dataAndMethodsContext;

  return (
    <div>
      <AppBar position='fixed' color='primary'>
        {logInType === 'default' && <DefaultTopToolBar></DefaultTopToolBar>}
        {logInType === 'signedIn' && <SignedInTopToolBar></SignedInTopToolBar>}
      </AppBar>
    </div>
  );
};

export default TopNavBar;
