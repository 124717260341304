import deleteItemDynamoDB from '../../api/deleteItemDynamoDB';
import { trainsTableName } from '../../api/apiConstants';

const deleteTrain = async (trainItemId: any, myToken: any, myCustomId: any) => {
  const data = await deleteItemDynamoDB(
    trainsTableName,
    trainItemId,
    myToken,
    myCustomId
  );
  if (data.err) {
    return null;
  }
  return data;
};

export default deleteTrain;
