import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
// THEME
import { ThemeProvider } from '@mui/material';
import muiTheme from './theme/muiTheme';
import { Amplify } from 'aws-amplify';
import awsConfig from './amplify-config';

const container = document.getElementById('root');

const root = createRoot(container!);

Amplify.configure(awsConfig);

root.render(
  <ThemeProvider theme={muiTheme}>
    <App />
  </ThemeProvider>
);
