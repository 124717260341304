import React, { useContext } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AlertDialogContext from '../../context/alertDialog/alertDialogContext';

const AlertDialog: any = () => {
  const alertDialogContext: any = useContext(AlertDialogContext);
  const { alertDialog, closeDialog } = alertDialogContext;

  return (
    alertDialog != null && (
      <div>
        <Dialog
          open={alertDialog.dialogOpen}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            <i className='fas fa-exclamation-triangle'></i>
            {'  '}
            {alertDialog.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {alertDialog.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={() => closeDialog()}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};

export default AlertDialog;
