import React, { useContext, useEffect } from 'react';
import TopNavBar from '../components/toolBars/TopNavBar';
import DataAndMethodsContext from '../context/dataAndMethods/dataAndMethodsContext';
import TrainsInventory from '../components/componentLists/train/Trains';
import BotNavBar from '../components/toolBars/BotNavBar';
import TrainDialog from '../components/dialogs/TrainDialog';
import HobbyistDialog from '../components/dialogs/HobbyistDialog';
import AlertDialog from '../components/dialogs/AlertDialog';
import HelpDialog from '../components/dialogs/HelpDialog';
import DeleteConfirmDialog from '../components/dialogs/DeleteConfirmDialog';
import SignInRegDialog from '../components/dialogs/SignInRegDialog';
import TrainSetDialog from '../components/dialogs/TrainSetDialog';
import TrainSetInventory from '../components/componentLists/trainSet/TrainSets';

const Home = () => {
  useEffect(() => {
    async function fetchData() {
      let todaysDate = new Date();
      setTodaysDate(todaysDate);
      setSelectedDate(todaysDate);
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const dataAndMethodsContext = useContext(DataAndMethodsContext);
  const { myStates, logInType, setSelectedDate, setTodaysDate }: any =
    dataAndMethodsContext;

  return (
    <>
      <AlertDialog />
      <DeleteConfirmDialog />
      <SignInRegDialog />
      <TopNavBar />
      {logInType === 'default' && (
        <div className='container '>
          {myStates.info && <p className='p home-page-top-margin-normal'></p>}
          <HelpDialog />
          <p className='p home-page-bottom-margin'></p>
        </div>
      )}
      {logInType === 'signedIn' && (
        <div className='container hobbyist-page-top-margin'>
          {myStates.trainSettings && <TrainsInventory />}
          {myStates.trainSetSettings && <TrainSetInventory />}
          <TrainDialog />
          <HobbyistDialog />
          <TrainSetDialog />
          <p className='p hobbyist-page-bottom-margin'></p>
        </div>
      )}
      <BotNavBar />
    </>
  );
};

export default Home;
