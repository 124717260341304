import React, { useContext } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import DataAndMethodsContext from '../../context/dataAndMethods/dataAndMethodsContext';
import DefaultBotToolBar from './DefaultBotToolBar';
import SignedInBotToolBar from './SignedInBotToolBar';

const BottomNavBar = () => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);

  const { logInType } = dataAndMethodsContext;

  //TODO fix the bottom nav with these props not sure why they dont work --- showLabel="false" position="fixed" color="primary"

  return (
    <div color='primary'>
      <BottomNavigation>
        {logInType === 'default' && <DefaultBotToolBar></DefaultBotToolBar>}
        {logInType === 'signedIn' && <SignedInBotToolBar></SignedInBotToolBar>}
      </BottomNavigation>
    </div>
  );
};

export default BottomNavBar;
