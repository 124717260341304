import React, { useContext } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import DataAndMethodsContext from '../../context/dataAndMethods/dataAndMethodsContext';
import { Tooltip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const SignedInTopToolBar = () => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const {
    setTrainDialogData,
    setTrainDialogOpen,
    myStates,
    setTrainSetDialogData,
    setTrainSetDialogOpen,
    setImageEditorData,
  } = dataAndMethodsContext;

  const newTrainClick = () => {
    let myNewId = uuidv4();
    let myEditItem = {
      id: myNewId,
      title: '',
      modelNumber: '',
      description: '',
      roadName: '',
      yearBought: '',
      trainLocation: '',
      boxLocation: '',
      boughtPrice: 0,
      marketPrice: 0,
      categoryJSON: [],
      videoUrl: '',
      dialogType: 'Add',
    };
    let myImageEditorItem = {
      imageUrl: '',
      editMode: 'none',
      deleteFileName: '',
      width: 1,
      height: 1,
      aspectRatio: 1,
      blob: '',
      showDelete: true,
    };
    setImageEditorData(myImageEditorItem);
    setTrainDialogData(myEditItem);
    setTrainDialogOpen(true);
  };

  const newTrainSetClick = () => {
    let myNewId = uuidv4();
    let myEditItem = {
      id: myNewId,
      title: '',
      trainIdsJSON: [],
      dialogType: 'Add',
    };
    setTrainSetDialogData(myEditItem);
    setTrainSetDialogOpen(true);
  };

  return (
    <>
      <Toolbar>
        <div>
          <Tooltip title='Train settings'>
            <IconButton
              aria-label=''
              color={myStates['trainSettings'] ? 'default' : 'inherit'}
              onClick={() => dataAndMethodsContext.setMyState('trainSettings')}
            >
              <i className='icon-train'></i>
            </IconButton>
          </Tooltip>
          <Tooltip title='TrainSet settings'>
            <IconButton
              aria-label=''
              color={myStates['trainSetSettings'] ? 'default' : 'inherit'}
              onClick={() =>
                dataAndMethodsContext.setMyState('trainSetSettings')
              }
            >
              <i className='icon-train-set'></i>
            </IconButton>
          </Tooltip>
          {myStates['trainSettings'] && (
            <Tooltip title='Add train item'>
              <IconButton
                aria-label=''
                color='inherit'
                onClick={() => newTrainClick()}
              >
                <i className='icon-train-plus'></i>
              </IconButton>
            </Tooltip>
          )}
          {myStates['trainSetSettings'] && (
            <Tooltip title='Add trainSet item'>
              <IconButton
                aria-label=''
                color='inherit'
                onClick={() => newTrainSetClick()}
              >
                <i className='icon-train-set-plus'></i>
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    </>
  );
};

export default SignedInTopToolBar;
