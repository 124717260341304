export const trainsTableName = 'trains';
export const hobbyistsTableName = 'hobbyists';
export const trainSetsTableName = 'trainSets';
export const lambdaFunctionURL =
  'https://kd7snpev85.execute-api.us-east-1.amazonaws.com/default/i_want_to_dine_api';
export const apiName = 'i_want_to_dine_restaurant_api';
export const apiNameNoToken = 'i_want_to_dine_api';
export const apiPath = '/';
export const projectionExpressionHobbyist =
  'id,bio,dataJSON,email,trainIdsJSON,firstName,imageUrl,lastName,friendIdsJSON,trainSetIdsJSON';
export const projectionExpressionTrain =
  'id,categoryJSON,title,modelNumber,description,roadName,yearBought,trainLocation,boxLocation,boughtPrice,marketPrice,videoUrl,imageUrl';
export const projectionExpressionTrainSet = 'id,title,trainIdsJSON';
export const blankPlaceHolder = String.fromCharCode(30);
export const imagePath = 'https://iwanttodine.s3.amazonaws.com/public/';
export const blankImage =
  'https://iwanttodine.s3.amazonaws.com/public/blank.jpg';
export const websiteName = 'myTrainsList';
