import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DataAndMethodsContext from '../../context/dataAndMethods/dataAndMethodsContext';
import putTrainSet from '../../model/trainSet/putTrainSet';
import getTrainSets from '../../model/trainSet/getTrainSetsFromIds';
import Grid from '@mui/material/Grid';
import sortTrainSets from '../../model/trainSet/sortTrainSets';
import 'date-fns';
import TrainSetTrains from '../componentLists/trainSetTrain/TrainSetTrains';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import putHobbyist from '../../model/hobbyist/putHobbyist';

const TrainSetDialog: any = () => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const { id, title, trainIdsJSON, dialogType } =
    dataAndMethodsContext.trainSetDialogData;

  const { trainSetDialogData } = dataAndMethodsContext;

  const {
    trainSetDialogOpen,
    setTrainSetDialogOpen,
    setTrainSetDialogDataItem,
    idToken,
    customId,
    setTrainSets,
    setTrainSetDialogData,
    setHobbyist,
    hobbyist,
  } = dataAndMethodsContext;

  const handleClose = () => {
    setTrainSetDialogOpen(false);
  };

  const handleSave = () => {
    switch (dialogType) {
      case 'Edit':
        saveTrainSet();
        break;
      case 'Add':
        saveTrainSetAdd();
        break;
      default:
    }
    setTrainSetDialogOpen(false);
  };

  const saveTrainSet = async () => {
    let newTrainSet: any = {};
    newTrainSet.id = id;
    newTrainSet.title = title;
    newTrainSet.trainIdsJSON = trainIdsJSON;
    await putTrainSet(newTrainSet, idToken, customId);
    let myTrainSets = await getTrainSets(hobbyist.trainSetIdsJSON);
    myTrainSets = await sortTrainSets(myTrainSets, 'sortDate');
    setTrainSets(myTrainSets);
  };

  const saveTrainSetAdd = async () => {
    let newTrainSet: any = {};
    newTrainSet.id = id;
    newTrainSet.title = title;
    newTrainSet.trainIdsJSON = trainIdsJSON;
    await putTrainSet(newTrainSet, idToken, customId);
    let myNewHobbyist = JSON.parse(JSON.stringify(hobbyist));
    myNewHobbyist.trainSetIdsJSON.push(id);
    await putHobbyist(myNewHobbyist, idToken, customId);
    setHobbyist(myNewHobbyist);
    let myTrainSets = await getTrainSets(myNewHobbyist.trainSetIdsJSON);
    myTrainSets = await sortTrainSets(myTrainSets, 'sortDate');
    setTrainSets(myTrainSets);
  };

  const selectAllTrains = () => {
    let newTrainSetDialogData = JSON.parse(JSON.stringify(trainSetDialogData));
    newTrainSetDialogData.trainIdsJSON = JSON.parse(JSON.stringify({}));
    setTrainSetDialogData(newTrainSetDialogData);
  };

  const unSelectAllTrains = () => {
    let newTrainSetDialogData = JSON.parse(JSON.stringify(trainSetDialogData));
    newTrainSetDialogData.trainIdsJSON = [];
    setTrainSetDialogData(newTrainSetDialogData);
  };

  const changeTitle = (e: any) => {
    setTrainSetDialogDataItem('title', e.target.value);
  };

  return (
    <div>
      <Grid container justifyContent='space-around'>
        <Dialog
          open={trainSetDialogOpen}
          onClose={handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            {dialogType + ' trainSet'}
          </DialogTitle>
          <DialogContent>
            <TextField
              id='title'
              label='Title'
              type='text'
              fullWidth
              variant='filled'
              size='small'
              value={title}
              onChange={changeTitle}
            />
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>Trains</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <TrainSetTrains />
                </Grid>
              </AccordionDetails>
            </Accordion>
            <DialogActions>
              <Button onClick={() => selectAllTrains()} color='secondary'>
                Select All
              </Button>
              <Button onClick={() => unSelectAllTrains()} color='secondary'>
                Unselect All
              </Button>
            </DialogActions>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='secondary'>
              Cancel
            </Button>
            <Button onClick={() => handleSave()} color='primary'>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
};

export default TrainSetDialog;
