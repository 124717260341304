import putItemDynamoDB from '../../api/putItemDynamoDB';
import { hobbyistsTableName } from '../../api/apiConstants';

const putHobbyist = async (myHobbyist: any, myToken: any, myCustomId: any) => {
  const myNewHobbyist = JSON.parse(JSON.stringify(myHobbyist));
  const data = await putItemDynamoDB(
    hobbyistsTableName,
    myNewHobbyist,
    myToken,
    myCustomId
  );
  if (data.err) {
    return null;
  }
  return data;
};

export default putHobbyist;
