import getTrains from './getTrains';

const getHobbyistsTrains = async (hobbyist: any) => {
  let trainItemIds = [];

  for (let k = 0; k < hobbyist.trainIdsJSON.length; k++) {
    trainItemIds.push(hobbyist.trainIdsJSON[k]);
  }
  const myTrains = await getTrains(trainItemIds);

  return myTrains;
};

export default getHobbyistsTrains;
