import Resizer from 'react-image-file-resizer';

const resizeImageFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      720,
      1280,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

export default resizeImageFile;
