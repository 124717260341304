import getTrainSetsFromIds from './getTrainSetsFromIds';

const getHobbyistsTrainSets = async (hobbyist: any) => {
  let trainSetItemIds = [];

  for (let k = 0; k < hobbyist.trainSetIdsJSON.length; k++) {
    trainSetItemIds.push(hobbyist.trainSetIdsJSON[k]);
  }
  const TrainSets = await getTrainSetsFromIds(trainSetItemIds);

  return TrainSets;
};

export default getHobbyistsTrainSets;
