import React, { useContext } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DataAndMethodsContext from '../../context/dataAndMethods/dataAndMethodsContext';
import putHobbyist from '../../model/hobbyist/putHobbyist';
import saveImageToDatabase from '../../model/images/saveImageToDatabase';
import ImageEditor from '../imageEditor/ImageEditor';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HobbyistDialog: any = () => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const {
    idToken,
    customId,
    setHobbyistDialogOpen,
    setHobbyistDialogDataItem,
    hobbyistDialogOpen,
    setHobbyist,
    hobbyist,
  } = dataAndMethodsContext;

  const {
    id,
    firstName,
    lastName,
    bio,
    email,
    trainIdsJSON,
    friendIdsJSON,
    gymDayIdsJSON,
    trainSetIdsJSON,
    dataJSON,
    trainDaysJSON,
    dialogType,
    message,
  } = dataAndMethodsContext.hobbyistDialogData;

  const { deleteFileName, imageUrl, blob, editMode } =
    dataAndMethodsContext.imageEditorData;

  // edit logged in hobbyist save to database
  // get hobbyists for restaurant from database
  // update restaurant with these new hobbyists and save to database
  // update state for hobbyist, restaurantHobbyists
  const saveHobbyistEditMe = async () => {
    let newHobbyist: any = {};
    newHobbyist.id = id;
    newHobbyist.firstName = firstName;
    newHobbyist.lastName = lastName;
    newHobbyist.bio = bio;
    newHobbyist.email = email;
    newHobbyist.trainIdsJSON = trainIdsJSON;
    newHobbyist.friendIdsJSON = friendIdsJSON;
    newHobbyist.gymDayIdsJSON = gymDayIdsJSON;
    newHobbyist.trainSetIdsJSON = trainSetIdsJSON;
    newHobbyist.imageUrl = imageUrl;
    newHobbyist.dataJSON = dataJSON;
    newHobbyist.trainDaysJSON = trainDaysJSON;
    await saveImageToDatabase(
      deleteFileName,
      imageUrl,
      blob,
      editMode,
      idToken,
      customId
    );
    await putHobbyist(newHobbyist, idToken, customId);
    setHobbyist(newHobbyist);
  };

  const handleClose = () => {
    setHobbyistDialogOpen(false);
  };

  const selectAllDataItems = () => {};

  const unSelectAllDataItems = () => {};

  const handleSave = async () => {
    switch (dialogType) {
      case 'EditMe':
        saveHobbyistEditMe();
        break;
      default:
    }
    setHobbyistDialogOpen(false);
  };

  const changeFirstName = (e: any) => {
    setHobbyistDialogDataItem('firstName', e.target.value);
  };

  const changeLastName = (e: any) => {
    setHobbyistDialogDataItem('lastName', e.target.value);
  };

  const changeBio = (e: any) => {
    setHobbyistDialogDataItem('bio', e.target.value);
  };

  let dialogTitle = '';

  if (dialogType === 'EditMe') {
    dialogTitle = 'Edit my details';
  }

  let loggedInUserMessage = '';
  if (hobbyist.id === id) {
    loggedInUserMessage = 'Logged in user ' + email;
  }

  return (
    <div>
      <Dialog
        open={hobbyistDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>{dialogTitle}</DialogTitle>
        <DialogContent>
          <p>{loggedInUserMessage}</p>
          <TextField
            id='firstName'
            label='First name'
            type='text'
            fullWidth
            variant='filled'
            size='small'
            value={firstName}
            onChange={changeFirstName}
          />
          <TextField
            id='lastName'
            label='Last name'
            type='text'
            fullWidth
            variant='filled'
            value={lastName}
            onChange={changeLastName}
          />
          <TextField
            id='bio'
            label='Bio'
            type='text'
            fullWidth
            variant='filled'
            multiline={true}
            minRows='4'
            value={bio}
            onChange={changeBio}
          />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>Metrics</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                {/* <HobbyistDataInventory /> */}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <DialogActions>
            <Button onClick={() => selectAllDataItems()} color='secondary'>
              Add Metric
            </Button>
            <Button onClick={() => selectAllDataItems()} color='secondary'>
              Select All
            </Button>
            <Button onClick={() => unSelectAllDataItems()} color='secondary'>
              Unselect All
            </Button>
          </DialogActions>
          <p>Profile Image</p>
          <ImageEditor />
          <p>{message}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancel
          </Button>
          <Button onClick={() => handleSave()} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HobbyistDialog;
