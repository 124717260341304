import React, { useContext } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import DataAndMethodsContext from '../../context/dataAndMethods/dataAndMethodsContext';
import { Tooltip } from '@mui/material';

const SignedInBotToolBar = () => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const {
    setAuthToken,
    setIdToken,
    setCustomId,
    setLogInType,
    setHobbyistDialogData,
    setHobbyistDialogOpen,
    hobbyist,
    setTrains,
    setImageEditorData,
  } = dataAndMethodsContext;

  const logOut = () => {
    setAuthToken('');
    setIdToken('');
    setCustomId('');
    setLogInType('default');
    setTrains([]);
  };

  const handleEditHobbyist = () => {
    let myHobbyistData = {
      id: hobbyist.id,
      firstName: hobbyist.firstName,
      lastName: hobbyist.lastName,
      jobTitle: hobbyist.jobTitle,
      bio: hobbyist.bio,
      email: hobbyist.email,
      trainIdsJSON: hobbyist.trainIdsJSON,
      friendIdsJSON: hobbyist.friendIdsJSON,
      trainSetIdsJSON: hobbyist.trainSetIdsJSON,
      dataJSON: hobbyist.dataJSON,
      dialogType: 'EditMe',
    };
    setHobbyistDialogData(myHobbyistData);
    let myImageEditorItem = {
      imageUrl: hobbyist.imageUrl,
      editMode: 'none',
      deleteFileName: '',
      width: 1,
      height: 1,
      aspectRatio: 1,
      blob: '',
      showDelete: true,
    };
    setImageEditorData(myImageEditorItem);
    setHobbyistDialogOpen(true);
  };

  return (
    <>
      <Toolbar color='primary' component='div'>
        <Tooltip title='Edit hobbyist details'>
          <IconButton
            aria-label=''
            color='primary'
            onClick={() => handleEditHobbyist()}
          >
            <i className='fas fa-user-edit'></i>
          </IconButton>
        </Tooltip>
        <Tooltip title='Log out'>
          <IconButton aria-label='' color='primary' onClick={() => logOut()}>
            <i className='fas fa-sign-out-alt'></i>
          </IconButton>
        </Tooltip>
      </Toolbar>
    </>
  );
};

export default SignedInBotToolBar;
