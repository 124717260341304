import React, { useContext } from 'react';
import Checkbox from '@mui/material/Checkbox';
import DataAndMethodsContext from '../../../context/dataAndMethods/dataAndMethodsContext';

const TrainSetTrainCard = ({ Train }: any) => {
  const dataAndMethodsContext: any = useContext(DataAndMethodsContext);
  const { trainSetDialogData, setTrainSetDialogData } = dataAndMethodsContext;

  let TrainSelected = false;
  for (let j = 0; j < trainSetDialogData.trainIdsJSON.length; j++) {
    if (Train.id === trainSetDialogData.trainIdsJSON[j]) {
      TrainSelected = true;
      break;
    }
  }

  const changeTrainSelected = () => {
    let myNewTrainSetDialogData = JSON.parse(
      JSON.stringify(trainSetDialogData)
    );
    let myIndex = myNewTrainSetDialogData.trainIdsJSON.indexOf(Train.id, 0);
    if (myIndex !== -1) {
      myNewTrainSetDialogData.trainIdsJSON.splice(myIndex, 1);
    } else {
      myNewTrainSetDialogData.trainIdsJSON.push(Train.id);
    }
    setTrainSetDialogData(myNewTrainSetDialogData);
  };

  const items = [];
  for (let i = 0; i < Train.categoryJSON.length; i++) {
    switch (Train.categoryJSON[i]) {
      case 'meat':
        items.push(
          <i
            className='icon-tbone'
            key={Train.id + '_meat'}
            style={{ paddingRight: '.25rem' }}
          ></i>
        );
        break;
      default:
    }
  }

  return (
    <div className='card'>
      <h4>
        <Checkbox
          checked={TrainSelected}
          onChange={changeTrainSelected}
          name='checked'
          color='primary'
        />
        {' - '}
        {items}
        {Train.title}
      </h4>
    </div>
  );
};

export default TrainSetTrainCard;
